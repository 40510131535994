export const ONE_THIRD = 100 / 3;

export const ONE_FORTH = 100 / 4;

export const RESPONSIVE_MIN = 320;

export const RESPONSIVE_MAX = 1065;

export const GRID_GAP = 10;

export const GRID_SCALE = 0.75;

export const DEFAULT_LANGUAGES = [
  {
    language: '简体中文',
    prefix: "zh-cn",
  },
  {
    language: '繁體中文',
    prefix: "zh-tw",
  },
  {
    language: 'English',
    prefix: "en",
  },
  {
    language: 'Français',
    prefix: "fr",
  },
  {
    language: 'Français Canadien',
    prefix: "fr-ca",
  },
  {
    language: 'Deutsch',
    prefix: "de",
  },
  {
    language: 'Español',
    prefix: "es",
  },
  {
    language: 'Türkçe',
    prefix: "tr",
  },
];
