import React, {useContext} from "react";
import {Switch, Route, useHistory} from "react-router-dom/cjs/react-router-dom";
import "./app.scss";
import AppContextProvider, {AppContext} from "../../context/app-context";

const Welcome = React.lazy(() => import("../../pages/welcome/welcome"));
const Home = React.lazy(() => import("../../pages/home/home"));
const Hire = React.lazy(() => import("../../pages/hire/hire"));
const HireTwo = React.lazy(() => import("../../pages/hire/hire-two"));
const HireThree = React.lazy(() => import("../../pages/hire/hire-three"));
const Candidates = React.lazy(() => import("../../pages/candidates/candidates"));
const CandidatesTwo = React.lazy(() => import("../../pages/candidates/candidates-two"));
const CandidatesThree = React.lazy(() => import("../../pages/candidates/candidates-three"));
const CandidatesFour = React.lazy(() => import("../../pages/candidates/candidates-four"));
const Decision = React.lazy(() => import("../../pages/decision/decision"));
const DecisionTwo = React.lazy(() => import("../../pages/decision/decision-two"));
const DecisionThree = React.lazy(() => import("../../pages/decision/decision-three"));

export default function App() {
  const {browser} = useContext(AppContext);
  const {LANGUAGE} = require('../../../src/config/language');

  return (
    <AppContextProvider>
      <div className={`app app-browser--${browser?.name ?? "unknown"} app-browser--${browser?.id ?? "unknown"} app-lang-${LANGUAGE ?? "unknown"}`}>
        <React.Suspense fallback="Loading...">
          <Switch>
            <Route exact path="/" component={Welcome}/>
            <Route exact path="/:lang/home" component={Home}/>
            <Route exact path="/:lang/hire" component={Hire}/>
            <Route exact path="/:lang/hire-two" component={HireTwo}/>
            <Route exact path="/:lang/hire-three" component={HireThree}/>
            <Route exact path="/:lang/candidates" component={Candidates}/>
            <Route exact path="/:lang/candidates-two" component={CandidatesTwo}/>
            <Route exact path="/:lang/candidates-three" component={CandidatesThree}/>
            <Route exact path="/:lang/candidates-four" component={CandidatesFour}/>
            <Route exact path="/:lang/decision" component={Decision}/>
            <Route exact path="/:lang/decision-two" component={DecisionTwo}/>
            <Route exact path="/:lang/decision-three" component={DecisionThree}/>
          </Switch>
        </React.Suspense>
      </div>
    </AppContextProvider>
  );
}
