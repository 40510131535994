import React, {useCallback, useState} from "react";
import browserDetect from "browser-detect";
import {withScorm} from 'react-scorm-provider';
import {useHistory} from 'react-router-dom';
import {DEFAULT_LANGUAGES} from "../config/consts";

export const AppContext = React.createContext({});

const getLang = () => {
  return localStorage.getItem('language')
}

export default withScorm()(function AppContextProvider({children, sco}) {
  const history = useHistory();
  const [pageY, setPageY] = useState(0);
  const [progress, setProgress] = useState(0);
  const [language, setLanguage] = useState(getLang());
  const routeProgress = {
    '/': 0,
    [`/${language}/home`]: 10,
    [`/${language}/hire`]: 27,
    [`/${language}/hire-two`]: 36,
    [`/${language}/hire-three`]: 45,
    [`/${language}/candidates`]: 60,
    [`/${language}/candidates-two`]: 65,
    [`/${language}/candidates-three`]: 70,
    [`/${language}/candidates-four`]: 75,
    [`/${language}/decision`]: 87,
    [`/${language}/decision-two`]: 94,
    [`/${language}/decision-three`]: 100,
  };

  const browser = React.useMemo(() => {
    const result = browserDetect();
    const version = parseInt(result.versionNumber);
    return {
      id: `${result.name}-${version}`,
      name: result.name,
      mobile: result.mobile,
      version,
    };
  }, []);
  const setCurrentPageProgress = useCallback((complete = false) => {
    const keys = Object.keys(routeProgress);
    const routeIndex = keys.indexOf(history.location.pathname);
    if(routeProgress[keys[complete ? routeIndex : routeIndex - 1 !== -1 ? routeIndex -1 : routeIndex]] <= progress) {
      return;
    }
    const _progress = routeIndex - 1 < 0 ?( complete ? routeProgress['/'] : 0) : routeProgress[keys[complete ? routeIndex : routeIndex -1]];
    if (sco && sco.apiConnected) {
      sco.set('cmi.core.score.raw', _progress)
    }
    setProgress(_progress);
  }, [history.location.pathname, progress, sco, routeProgress]);
  const selectLanguage = useCallback((lang) => {
    localStorage.setItem('language', lang);
    setLanguage(localStorage.getItem('language'))
  }, []);

  const [redirected, setRedirected] = useState(false);
  const manageRedirect = React.useCallback(() => {
    if (!redirected) {
      const data = sco.get("cmi.suspend_data");
      let suspendData = {};
      let lastVisitedUrl = '';

      try {
        if (!data || data.length === 0) {
          return;
        }
        suspendData = JSON.parse(data);
        lastVisitedUrl = Object.keys(suspendData).pop();
        if (lastVisitedUrl !== '/') {
          const validLanguages = DEFAULT_LANGUAGES.map(({ prefix }) => prefix);
          const lang = lastVisitedUrl.split("/").find((part => validLanguages.includes(part)) ?? "en")
          setLanguage(lang)
          history.push(lastVisitedUrl);
          setProgress(Number(sco.get("cmi.core.score.raw")));
        }
      } catch (e) {
        console.error(e);
      }

      setRedirected(true);
    }
  }, [history, sco, redirected, setProgress]);

  const [statusCompleted, setStatusCompleted] = useState(false);
  const setStatus = React.useCallback(() => {
    if (!statusCompleted) {
      sco.setStatus('passed');
      setStatusCompleted(true);
    }
  }, [sco, statusCompleted]);

  React.useEffect(() => {
    if(progress === 100) {
      if (sco && sco.apiConnected) {
        sco.set('cmi.core.score.raw', 100)
        setStatus()
      }
    }
  }, [progress, sco, setStatus])


  React.useEffect(() => {
    if (sco && sco.apiConnected) {
      if (progress === 100) {
        setStatus();
      }

      manageRedirect();
    } else {
      console.log('sco not connected');
    }
  }, [manageRedirect, sco, setStatus, progress]);


  return (
    <AppContext.Provider value={{browser, setProgress, progress, sco, pageY, setPageY, setCurrentPageProgress, routeProgress, selectLanguage, language}}>
      {children}
    </AppContext.Provider>
  );
})
